<template>
  <div class="h-full">
    <div class="relative z-10 h-full mt-8 add-request  mx-6">
      <h5 class="title-addEstate mt-8 mb-4">درخواست بازدید ملک</h5>
      <div class="self-start">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <validation-provider name="تاریخ بازدید" rules="required" v-slot="{ errors }">
              <div class="check-estate__form-group text-right ">
                <label>زمان بازدید</label>
                <DatePicker
                    v-model="date"
                    type="datetime"
                    color="#361c3b"
                    custom-input="#dateTime"
                    format="YYYY-MM-DD HH:mm"
                    display-format="jYYYY/jMM/jDD HH:mm"
                    :min="min"
                />
                <input
                    id="dateTime"
                    type="text"
                    class="input-border mt-3"
                    placeholder="انتخاب زمان بازدید"
                >
                <span class="text-sm text-red-500 text-right inline-block mt-1">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider name="شماره تماس" rules="required|min:10" v-slot="{ errors }">
              <div class="check-estate__form-group text-right ">
                <label>شماره تماس</label>
                <input
                    type="number"
                    pattern="\d*"
                    class="input-border mt-3"
                    placeholder="شماره تماس"
                    v-model="phone"
                >
                <span class="text-sm text-red-500 text-right inline-block mt-1">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider name="توضیحات" rules="required|min:10" v-slot="{ errors }">
              <div class="check-estate__form-group text-right ">
                <label>توضیحات آگهی</label>
                <textarea
                    class="input-border bg-transparent mt-3"
                    placeholder="توضیحات آگهی"
                    v-model="description"
                    rows="5"
                ></textarea>
                <span class="text-sm text-red-500 text-right inline-block mt-1">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <div>
              <button class="btn-custom w-full">
                ثبت و ادامه
                <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
              </button>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import DatePicker from 'vue-persian-datetime-picker'
export default {
  name: "addRequestStep1",
  components :{
    DatePicker
  },
  data(){
    return{
      postalCode : '' ,
      errorText : '',
      isActive : false,
      phone : '',
      description : '',
      date : '',
      min : (new Date()).toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ")
    }
  },
  methods:{
    onSubmit(){
      const data = {
        contact : this.phone,
        visit_time : this.date ,
        description : this.description
      }
      this.$store.commit('addRequest/SET_STEP1', data);
      this.$router.push({name : 'AddRequestStep2'})
    }
  },
  computed :{

  },
  mounted() {
    this.$store.commit('addRequest/CLEAR_STATE')
    this.$store.commit('map/CLEAR_SELECT_LOCATION')
  }
}
</script>

<style scoped>
.vpd-main >>> svg{
  display: inline-block !important;
}
</style>